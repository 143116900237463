import { Component } from '@angular/core';
import { MenuController } from '@ionic/angular';
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyClftKi_FC71moq7qqq-KgmbR2Th3svE1A",
  authDomain: "professor-jacqueson.firebaseapp.com",
  projectId: "professor-jacqueson",
  storageBucket: "professor-jacqueson.appspot.com",
  messagingSenderId: "417745652387",
  appId: "1:417745652387:web:6bbb9bda1e7a76db990d16",
  measurementId: "G-B1F1KQYV44"
};

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})


export class AppComponent {
  
  constructor(private menu: MenuController) {
    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);
  }

  openFirst() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }
}
